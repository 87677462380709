import React, {useState, useRef, useEffect} from 'react';
import NewsItem from './NewsItem';
import GalleryPage from './GalleryPage';
import NewsArticle from './NewsArticle';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

const host ="https://www.siteexpired.com/wp-json/wp/v2";

const HOME = "home";
const NEWS = "news";
const GALLERY = "gallery";
const TROPHIES = "trophies";
const GUIDES = "guides";

function NavMenu(props) {
    const [active, setActive] = useState(HOME);

    let location = useLocation();

    function showNewsItem(item) {
        let content = <div>
            <NewsArticle item={item}/>
        </div>
        props.setContent(content);
    }

    useEffect(() => {
        if(location){
            let path = location.pathname;
            if(path.includes('gallery')) {
                setActive(GALLERY);
            } else if(path.includes('news')) {
                setActive(NEWS);
            }else if(path.includes('guides')) {
                setActive(GUIDES);
            } else if(path.includes('trophies')) {
                setActive(TROPHIES);
            } else  {
                setActive(HOME);
            }
        }
      }, [location]);

    


    return(
    <div className="navMenu">
        <Link to="/home">
        <div className={`navItem ${(active === HOME) && "activeNav" }`}>Home</div>
        </Link>
        <Link to="/gallery">
        <div className={`navItem ${(active === GALLERY) && "activeNav" }`} >Gallery</div>
        </Link>
        <Link to="/guides">
        <div className={`navItem ${(active === GUIDES) && "activeNav" }`} >Guides</div>
        </Link>
        <Link to="/trophies">
        <div className={`navItem ${(active === TROPHIES) && "activeNav" }`} >Trophies</div>
        </Link>
        <Link to="/news">
        <div className={`navItem ${(active === NEWS) && "activeNav" }`} >News</div>
        </Link>
    </div>
    );
}

export default NavMenu;