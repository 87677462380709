export const pubNames = {
    trooper: "Trooper",
    consular: "Consular",
    knight: "Knight",
    smuggler: "Smuggler",
    commando: "Commando",
    vanguard: "Vanguard",
    shadow: "Shadow",
    sage: "Sage",
    sentinel: "Sentinel",
    guardian: "Guardian",
    scoundrel: "Scoundrel",
    gunslinger: "Gunslinger",
    gunnery: "Gunnery",
    assault: "Assault Specialist",
    combatMedic: "Combat Medic",
    plasmatech: "Plasmatech",
    tactics: "Tactics",
    infiltration: "Infiltration",
    serenity: "Serenity",
    telekinetics: "Telekinetics",
    balance: "Balance",
    seer: "Seer",
    watchman: "Watchman",
    combat: "Combat",
    concentration: "Concentration",
    vigilance: "Vigilance",
    focus: "Focus",
    ruffian: "Ruffian",
    scrapper: "Scrapper",
    sawbones: "Sawbones",
    dirtyFighting: "Dirty Fighting",
    saboteur: "Saboteur",
    sharpshooter: "Sharpshooter",
    defense:"Defense",
    kineticCombat:"Kinetic Combat",
    shieldSpecialist:"Shield Specialist"
};

export const impNames = {
    trooper: "Bounty Hunter",
    consular: "Inquisitor",
    knight: "Warrior",
    smuggler: "Agent",
    commando: "Mercenary",
    vanguard: "Powertech",
    shadow: "Assassin",
    sage: "Sorcerer",
    sentinel: "Marauder",
    guardian: "Juggernaut",
    scoundrel: "Operative",
    gunslinger: "Sniper",
    gunnery: "Arsenal",
    assault: "Innovative Ordnace",
    combatMedic: "Bodyguard",
    plasmatech: "Pyrotech",
    tactics: "Advanced Prototype",
    infiltration: "Deception",
    serenity: "Hatred",
    telekinetics: "Lightning",
    balance: "Madness",
    seer: "Corruption",
    watchman: "Annihilation",
    combat: "Carnage",
    concentration: "Fury",
    vigilance: "Vengeance",
    focus: "Rage",
    ruffian: "Lethality",
    scrapper: "Concealment",
    sawbones: "Medicine",
    dirtyFighting: "Virulence",
    saboteur: "Engineering",
    sharpshooter: "Marksman",
    defense:"Immortal",
    kineticCombat:"Darkness",
    shieldSpecialist:"Shield Tech"
}