import React, {useState, useRef, useEffect} from 'react';
import {useInterval} from './interval';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";

const host ="https://www.siteexpired.com/wp-json/wp/v2";

function LoadAnimation(props) {
    const [step, setStep] = useState(1);
    let location = useLocation();
    useInterval(() => {
        if(step >= 94) {
            window.localStorage.setItem('skipIntro', 'true');
            setStep(step +1);
          } else {
            setStep(step +1);
          }
      }, 170);

      let loadCommand = "";
  if(step > 10){
    loadCommand = "load_guild".substr(0, step - 9);
  }

    let result = step < 95 ? <div className={`mainContent`}>
        <div className={`fade ${step >86 && "faded"}`}>
        <div className="background" style={{display:'none'}}/>
        <div className="background2" style={{display:'none'}}/>
        {step < 95 && <div className="loader">$> {loadCommand}{ step < 20 && <span className="blinking-cursor">|</span>}</div>}
        { (step > 20 && step < 30) &&<div className="loader loading"> LOADING OFFICERS...</div>}
        {step > 30 &&<div className="loader success">OK</div>}
        {(step > 30 && step < 40) &&<div className="loader loading"> LOADING MEMBERS...</div>}
        {step > 40 &&<div className="loader success">OK</div>}
        {(step > 40 && step < 50) &&<div className="loader loading"> LOADING GUILD NAME...</div>}
        {step > 50 &&<div className="loader error">ERROR: NAME <span className="errorname">EXPIRED</span></div>}
        {(step > 60 && step < 70) &&<div className="loader loading"> ATTEMPTING NAME RECOVERY...</div>}
        {step > 70 &&<div className="loader error">ERROR: BACKUP <span className="errorname">CORRUPTED</span></div>}
        {step >= 80 && <div className="loader">FATAL ERROR: <span className="blinking">TERMINATING</span></div>}
        </div>
        </div>
        : <Redirect
            to={{
            pathname: "/home",
            state: { from: location }
            }}
    />;
    return result;
}

export default LoadAnimation;