import React, { useState, useRef, useEffect } from 'react';
import { AnimatedSwitch, spring } from 'react-router-transition';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";

function ClassImageLink(props) {
    return(
        <div className="classLinkDiv">
            <img className="classLinkImg" src={props.src}/>
            <div className="classLinkText">
            {props.name}
            </div>
        </div>
    )
}

export default ClassImageLink;