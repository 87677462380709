import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const host ="https://www.siteexpired.com/wp-json/wp/v2";

function NewsItem(props) {

    const [thumbnail, setThumbnail] = useState(<div className="thumbPlaceholder"/>);
    const [author, setAuthor] = useState({name:''});

    useEffect(() => {
        if(props.item._links["wp:featuredmedia"]){
            fetch(`${props.item._links["wp:featuredmedia"][0].href}`)
            .then(res => res.json() )
            .then(res => {
                setThumbnail(<img className="thumb" src={res.media_details.sizes.thumbnail.source_url}/>);
            });
        }
    }, [props.item]);

    useEffect(() => {
        if(props.item._links["author"]){
            fetch(`${props.item._links["author"][0].href}`)
            .then(res => res.json() )
            .then(res => {
                setAuthor(res);
            });
        }
    }, [props.item]);

    const hasMore = (props.item.excerpt.rendered.trim().length !== props.item.content.rendered.trim().length);

    const avatar = author && author.avatar_urls && author.avatar_urls['48'];

    return(
        <div style={{float:'left', clear:'both', marginBottom:'20px'}}>
        {thumbnail}
        <div style={{float:"left", paddingLeft:'20px', height:'150px',position:'relative',paddingRight:'71px',verticalAlign:'middle'}}>
        <h2 style={{marginTop:'0px',marginBottom:'0px'}}>{props.item.title.rendered}</h2>
        <div style={{marginBottom:'10px', marginTop:'-0.8em'}} dangerouslySetInnerHTML={{__html:props.item.excerpt.rendered}}/>
    <div data-tip={author.name} style={{position:'absolute',top:'0px',right:'0px'}}>{avatar && <img style={{borderRadius:'28px',float:'left',border:'4px solid #607D8B'}} src={avatar} title={author.name}/>}</div>
    <ReactTooltip place="right" type="dark" effect="solid"/>
        <div style={{position:'absolute',bottom:'0px'}}>{props.item && new moment(props.item.date).fromNow()}{hasMore && <Link to={`/news/${props.item.id}`}><div style={{display:'inline',marginLeft:'15px'}} className="readMore" >Read More</div></Link>}</div>
        </div>
        </div>
    )
}

export default NewsItem;