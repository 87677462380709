import React, { useState, useRef, useEffect } from 'react';
import { AnimatedSwitch, spring } from 'react-router-transition';
import ClassImageLink from './ClassImageLink';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";

const host ="https://www.siteexpired.com/wp-json/wp/v2";
const ids = {
    "medic-guard": {
        "pub":211,
        "imp":212
    },
    "gunnery-arsenal": {
        "pub":213,
        "imp":214
    },
    "assault-io": {
        "pub":215,
        "imp":216
    },
    "tactics-ap": {
        "pub":217,
        "imp":218
    },
    "plasma-pyro": {
        "pub":219,
        "imp":220
    },
    "watch-anni": {
        "pub":191,
        "imp":192
    },
    "combat-carnage": {
        "pub":193,
        "imp":194
    },
    "conc-fury": {
        "pub":195,
        "imp":196
    },
    "vigi-veng": {
        "pub":197,
        "imp":198
    },
    "focus-rage": {
        "pub":199,
        "imp":200
    },
    "seer-corrup": {
        "pub":201,
        "imp":202
    },
    "tk-light": {
        "pub":203,
        "imp":204
    },
    "balance-madness": {
        "pub":205,
        "imp":206
    },
    "seren-hatred": {
        "pub":209,
        "imp":210
    },
    "infil-decep": {
        "pub":207,
        "imp":208
    },
    "df-viru": {
        "pub":179,
        "imp":180
    },
    "sab-engi": {
        "pub":183,
        "imp":184
    },
    "sharp-mm": {
        "pub":181,
        "imp":182
    },
    "scrap-conceal": {
        "pub":187,
        "imp":188
    },
    "ruff-leth": {
        "pub":185,
        "imp":186
    },
    "saw-med": {
        "pub":190,
        "imp":189
    },
    "def-immort": {
        "pub":329,
        "imp":330
    },
    "kinet-dark": {
        "pub":331,
        "imp":332
    },
    "shield": {
        "pub":333,
        "imp":334
    }
}

function ClassGuide(props) {
    const [data,setData] = useState("");
    let { spec } = useParams();
    let { faction } = props;

    useEffect(() => {
        if(ids[spec] && ids[spec][faction]) {
            fetch(`${host}/guides/${ids[spec][faction]}`)
            .then(res => res.json() )
            .then(res => {
                setData(res);
        });
        }
    }, [spec, faction])
    return (
        <div className="classGuideDiv">
            {data && <div className="guideTitle" dangerouslySetInnerHTML={{__html:data.title.rendered}}/>}
            {data && <div dangerouslySetInnerHTML={{__html:data.content.rendered}}/>}
        </div>
    )
}

export default ClassGuide;