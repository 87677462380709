import React, {useState, useRef, useEffect} from 'react';
import NewsItem from './NewsItem';
import NewsArticle from './NewsArticle';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import { AnimatedSwitch, spring } from 'react-router-transition';

const host ="https://www.siteexpired.com/wp-json/wp/v2";

function NewsPage(props){

    const [res, setRes] = useState([]);

        useEffect(() => {
            fetch(`${host}/posts`)
        .then(res => res.json() )
        .then(res => {
            setRes(res);
        });
          }, []);

return(<div>
        <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      mapStyles={(styles) => {
        return {
          position: (styles.opacity === 1) ? 'relative': 'absolute',
          width: '1066px',
          height:'100%',
          opacity: styles.opacity,
        }
      }}
    >
            <Route path="/news/:itemId">
                <NewsArticle/>
                    
            </Route>
            <Route path="*">
                    
                    <div className="wp-block-cover has-background-dim newsCover" style={{backgroundImage:'url(https://www.siteexpired.com/wp-content/uploads/2019/11/tat-1024x576.png)', minHeight:'300px !important'}}><div className="wp-block-cover__inner-container">
                        <p style={{textAlign:'center'}} className="has-large-font-size">EXPIRED NEWS</p>
                    </div></div>
                
                    {res.map( (item) => {
                        return(
                            <NewsItem 
                            item={item}
                            showNewsItem={props.showNewsItem}
                            />
                        )
                    })}
                    </Route>
                    </AnimatedSwitch>
                </div>)
}

export default NewsPage;