import React, {useState, useRef, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {useInterval} from './interval';
import NavMenu from './NavMenu';
import Headroom from 'react-headroom';
import LoadAnimation from './LoadAnimation';
import ContentContainer from './ContentContainer';

const host ="https://www.siteexpired.com/wp-json/wp/v2";

function MainContent(props) {
  const [content, setContent] = useState("");
  const mainDiv = useRef(null);

  const skipIntro = window.localStorage.getItem('skipIntro');

  function mainPageContent() {
    return(
      <div style={{height:'100%',position:'relative'}}>
      <div className="loaded">
        GUILD NAME HAS <span className="errorname">EXPIRED</span><br/>
        GUILD NAME IS <span className="errorname">ÇØRRÜPTÊD</span>
        <img src="https://www.siteexpired.com/wp-content/uploads/2019/11/heardlrytp.png" className="logoImg"/>
        </div>
        <div className="mainContent">
        <Headroom
          pinStart="160"
          disableInlineStyles
        >
        <NavMenu
          setContent={setContent}
        />
        </Headroom>
        <ContentContainer/>
        </div>
      </div>
    );
  }

  return (
    <div style={{height:'100%',position:'relative'}}>
      <Router>
      <Switch>
      <Route exact path="/">
        {skipIntro ? mainPageContent() : <LoadAnimation/>}
        
      </Route>
      <Route exact path="/intro">
        <LoadAnimation/>
      </Route>
      <Route path="*">
        {mainPageContent()}
        </Route>
      </Switch>
      </Router>
    </div>
  );
}

export default MainContent;
