import React, {useState, useRef, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import HomePage from './HomePage';
import GalleryPage from './GalleryPage';
import TrophyPage from './TrophyPage';
import NewsPage from './NewsPage';
import { AnimatedSwitch, spring } from 'react-router-transition';
import ClassGuides from './ClassGuides';

function ContentContainer(props) {
    
    return(
        
        <div className="contentContainer">
            <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      mapStyles={(styles) => {
        return {
          position: (styles.opacity === 1) ? undefined: 'absolute',
          width: '1066px',
          height: (styles.opacity === 1) ? undefined : '100%',
          opacity: styles.opacity,
        }
      }}
    >
                <Route path="/gallery">
                    <GalleryPage/>
                </Route>
                <Route path="/trophies">
                    <TrophyPage/>
                </Route>
                <Route path="/news/author">
                    <HomePage/>
                </Route>
                <Route path="/news">
                    <NewsPage/>
                </Route>
                <Route path="/guides">
                  <ClassGuides/>
                </Route>
                <Route path="*">
                    <HomePage/>
                </Route>
                </AnimatedSwitch>
        </div>
        
    );
}

export default ContentContainer;