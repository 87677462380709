import React, { useState, useRef, useEffect } from 'react';
import { AnimatedSwitch, spring } from 'react-router-transition';
import ClassImageLink from './ClassImageLink';
import ClassGuide from './ClassGuide';
import {pubNames, impNames} from './Constants';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";

const PUB = "pub";
const IMP = "imp";
const host ="https://www.siteexpired.com/wp-json/wp/v2";

function ClassGuides(props) {
    const [faction, setFaction] = useState(PUB);
    const [intro, setIntro] = useState("");

    useEffect(() => {
            fetch(`${host}/pages/339`)
            .then(res => res.json() )
            .then(res => {
                setIntro(res.content.rendered);
        });
    }, [])

    let names = ((faction === PUB)) ? pubNames : impNames;
    let { spec } = useParams();

    return (
        <div style={{position:'relative',width:'100%',paddingTop:'20px'}}>
            <div style={{display:'inline'}} onClick={() => setFaction(PUB)}><img className={`factionImg ${faction===PUB ? "factionActive" : ""}`} src ="https://www.siteexpired.com/wp-content/uploads/2019/11/pub2.png"/></div>
            <div style={{display:'inline'}} onClick={() => setFaction(IMP)}><img className={`factionImg ${faction===IMP ? "factionActive" : ""}`} src ="https://www.siteexpired.com/wp-content/uploads/2019/11/imp2.png"/></div>
            <Link to="/guides" style={{textDecoration:'none'}}>
                <div className="guideHomeLink" style={{display:'inline',float:'none',position:'relative',top:'-10px'}}>Guides Home</div>
            </Link>
            <Route exact path="/guides">
                <div className="guidesIntro" dangerouslySetInnerHTML={{__html:intro}}></div>
            </Route>
            <AnimatedSwitch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
                mapStyles={(styles) => {
                    return {
                        position: (styles.opacity === 1) ? undefined : 'absolute',
                        width: '1066px',
                        opacity: styles.opacity,
                    }
                }}
            >
                <Route exact path="/guides/:class/:advanced/:spec">
                    <ClassGuide
                        faction={faction}/>
                </Route>
                <Route exact path="/guides/trooper-hunter/mando-merc">
                    <ClassImageLink
                            name={names.commando}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/mando.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/merc.png"}
                            ></ClassImageLink>
                    <Link to="/guides/trooper-hunter/mando-merc/medic-guard">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/medic.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/bodyguard.png"}/>
                    </Link>
                    <Link to="/guides/trooper-hunter/mando-merc/gunnery-arsenal">
                    <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/gunnery.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/arsenal.png"}/>
                    </Link>
                    <Link to="/guides/trooper-hunter/mando-merc/assault-io">
                    <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/assault.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/io.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/trooper-hunter/vanguard-pt">
                    <ClassImageLink
                            name={names.vanguard}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/vang.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/pt.png"}
                            />
                    <Link to="/guides/trooper-hunter/vanguard-pt/shield">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/shieldspec.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/shield.png"}/>
                    </Link>
                    <Link to="/guides/trooper-hunter/vanguard-pt/tactics-ap">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/tactics.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/ap.png"}/>
                    </Link>
                    <Link to="/guides/trooper-hunter/vanguard-pt/plasma-pyro">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/plasma.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/pyro.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/knight-warrior/guard-jugg">
                    <ClassImageLink
                            name={names.guardian}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/guard.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/jugg.png"}
                            />
                    <Link to="/guides/knight-warrior/guard-jugg/def-immort">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/def.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/immo.png"}/>
                    </Link>
                    <Link to="/guides/knight-warrior/guard-jugg/vigi-veng">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/vigi.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/veng.png"}/>
                    </Link>
                    <Link to="/guides/knight-warrior/guard-jugg/focus-rage">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/focus.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/rage.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/knight-warrior/sent-mara">
                    <ClassImageLink
                            name={names.sentinel}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sent.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/mara.png"}
                            />
                    <Link to="/guides/knight-warrior/sent-mara/watch-anni">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/watch.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/anni.png"}/>
                    </Link>
                    <Link to="/guides/knight-warrior/sent-mara/combat-carnage">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/combat.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/carn.png"}/>
                    </Link>
                    <Link to="/guides/knight-warrior/sent-mara/conc-fury">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/conc.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/fury.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/consular-inquis/sage-sorc">
                    <ClassImageLink
                            name={names.sage}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sage.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/sorc1.png"}
                            />
                    <Link to="/guides/consular-inquis/sage-sorc/seer-corrup">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/seer.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/corru.png"}/>
                    </Link>
                    <Link to="/guides/consular-inquis/sage-sorc/tk-light">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/tk.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/light.png"}/>
                    </Link>
                    <Link to="/guides/consular-inquis/sage-sorc/balance-madness">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/bal.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/mad.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/consular-inquis/shadow-sin">
                    <ClassImageLink
                            name={names.shadow}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/shadow.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/sin.png"}
                            />
                    <Link to="/guides/consular-inquis/shadow-sin/kinet-dark">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/kinet.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/dark.png"}/>
                    </Link>
                    <Link to="/guides/consular-inquis/shadow-sin/seren-hatred">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/seren.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/hate.png"}/>
                    </Link>
                    <Link to="/guides/consular-inquis/shadow-sin/infil-decep">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/infil.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/decep.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/smuggler-agent/slinger-sniper">
                    <ClassImageLink
                        name={names.gunslinger}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sling.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/snipe.png"}
                            />
                    <Link to="/guides/smuggler-agent/slinger-sniper/df-viru">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/df.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/viru.png"}/>
                    </Link>
                    <Link to="/guides/smuggler-agent/slinger-sniper/sab-engi">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sab.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/engi.png"}/>
                    </Link>
                    <Link to="/guides/smuggler-agent/slinger-sniper/sharp-mm">
                        <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sharp.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/mm.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/smuggler-agent/scoundrel-op">
                    <ClassImageLink
                            name={names.scoundrel}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/scoun.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/op-1.png"}
                            />
                    <Link to="/guides/smuggler-agent/scoundrel-op/scrap-conceal">
                    <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/scrap.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/conceal.png"}/>
                    </Link>
                    <Link to="/guides/smuggler-agent/scoundrel-op/ruff-leth">
                    <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/ruff.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/leth.png"}/>
                    </Link>
                    <Link to="/guides/smuggler-agent/scoundrel-op/saw-med">
                    <img className="specImg" src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/saw.png"
                        : "https://www.siteexpired.com/wp-content/uploads/2019/11/medicine.png"}/>
                    </Link>
                </Route>
                <Route exact path="/guides/trooper-hunter">
                    <Link to="/guides/trooper-hunter/mando-merc">
                        <div>{names.commando}</div>
                    </Link>
                    <Link to="/guides/trooper-hunter/vanguard-pt">
                        <div>{names.vanguard}</div>
                    </Link>
                </Route>
                <Route exact path="/guides/knight-warrior">
                    <Link to="/guides/knight-warrior/guard-jugg">
                        <div>{names.guardian}</div>
                    </Link>
                    <Link to="/guides/knight-warrior/sent-mara">
                        <div>{names.sentinel}</div>
                    </Link>
                </Route>
                <Route exact path="/guides/consular-inquis">
                    <Link to="/guides/consular-inquis/sage-sorc">
                        <div>{names.sage}</div>
                    </Link>
                    <Link to="/guides/consular-inquis/shadow-sin">
                        <div>{names.shadow}</div>
                    </Link>
                </Route>
                <Route exact path="/guides/smuggler-agent">
                    <Link to="/guides/smuggler-agent/slinger-sniper">
                        <div>{names.gunslinger}</div>
                    </Link>
                    <Link to="/guides/smuggler-agent/scoundrel-op">
                        <div>{names.scoundrel}</div>
                    </Link>
                </Route>
                <Route path="*">
                    <div className="guideHome">
                    <Link to="/guides/trooper-hunter/mando-merc" onClick={() => window.scrollTo(0,0)}>
                    <ClassImageLink
                            name={names.commando}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/mando.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/merc.png"}
                            ></ClassImageLink>
                    </Link>
                    <Link to="/guides/trooper-hunter/vanguard-pt" onClick={() => window.scrollTo(0,0)}>
                    <ClassImageLink
                            name={names.vanguard}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/vang.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/pt.png"}
                            />
                    </Link>
                    <Link to="/guides/knight-warrior/sent-mara" onClick={() => window.scrollTo(0,0)}>
                        <ClassImageLink
                            name={names.sentinel}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sent.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/mara.png"}
                            />
                    </Link>
                    <Link to="/guides/knight-warrior/guard-jugg" onClick={() => window.scrollTo(0,0)}>
                        <ClassImageLink
                            name={names.guardian}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/guard.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/jugg.png"}
                            />
                    </Link>
                    <Link to="/guides/consular-inquis/sage-sorc" onClick={() => window.scrollTo(0,0)}>
                        <ClassImageLink
                            name={names.sage}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sage.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/sorc1.png"}
                            />
                    </Link>
                    <Link to="/guides/consular-inquis/shadow-sin" onClick={() => window.scrollTo(0,0)}>
                        <ClassImageLink
                            name={names.shadow}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/shadow.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/sin.png"}
                            />
                    </Link>
                    <Link to="/guides/smuggler-agent/slinger-sniper" onClick={() => window.scrollTo(0,0)}>
                        <ClassImageLink
                            name={names.gunslinger}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/sling.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/snipe.png"}
                            />
                    </Link>
                    <Link to="/guides/smuggler-agent/scoundrel-op" onClick={() => window.scrollTo(0,0)}>
                        <ClassImageLink
                            name={names.scoundrel}
                            src={(faction === PUB) ? "https://www.siteexpired.com/wp-content/uploads/2019/11/scoun.png"
                                : "https://www.siteexpired.com/wp-content/uploads/2019/11/op-1.png"}
                            />
                    </Link>
                    </div>
                </Route>
            </AnimatedSwitch>
        </div>
    );
}

export default ClassGuides;