import React, {useState, useRef, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

const host ="https://www.siteexpired.com/wp-json/wp/v2";

function NewsArticle(props) {

    const [item, setItem] = useState(null);
    const [image, setImage] = useState(<div></div>);

    const { itemId } = useParams();
    useEffect(() => {
        fetch(`${host}/posts/${itemId}`)
    .then(res => res.json() )
    .then(res => {
        setItem(res);
        });
      }, []);

    useEffect(() => {
        if(item && item._links["wp:featuredmedia"]){
            fetch(`${item._links["wp:featuredmedia"][0].href}`)
            .then(res => res.json() )
            .then(res => {
                setImage(<img style={{float:'right',width:'600px'}} src={res.media_details.sizes.large.source_url}/>);
            });
        }
    }, [item]);

    return(
        <div style={{paddingTop:'10px'}}>
            {item && <div>
            {image}
            <h2>{item.title.rendered}</h2>
            <div dangerouslySetInnerHTML={{__html:item.content.rendered}}/>
            </div>}
        </div>
    );
}

export default NewsArticle;