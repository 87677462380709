import React, {useState, useRef, useEffect} from 'react';

const host ="https://www.siteexpired.com/wp-json/wp/v2";


function HomePage(props){

    const [content, setContent] = useState("");
    useEffect(() => {
        fetch(`${host}/pages/5`)
        .then(res => res.json() )
        .then(res => {
          setContent(res.content.rendered);
        })
      }, []);
    return(<div dangerouslySetInnerHTML={{__html:content}}>

    </div>);
}

export default HomePage;