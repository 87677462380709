import React, {useState, useRef, useEffect} from 'react';

const host ="https://www.siteexpired.com/wp-json/wp/v2";

function TrophyPage(props) {
    const [content, setContent] = useState("");

    fetch(`${host}/pages/36`)
        .then(res => res.json() )
        .then(res => {
            setContent(res.content.rendered);
        });

        return(<div className="trophyPage" dangerouslySetInnerHTML={{__html:content}}>

            </div>);
}

export default TrophyPage;