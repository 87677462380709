import React, {useState, useRef, useEffect} from 'react';
import Gallery from 'react-grid-gallery';

const host ="https://www.siteexpired.com/wp-json/wp/v2";

function GalleryPage(props) {

    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch(`${host}/media?parent=17&per_page=50`)
        .then(res => res.json() )
        .then(res => {
            let array = res.map((item) => {
                return {
                    src: item.source_url,
                    thumbnail: item.media_details.sizes.medium.source_url,
                    thumbnailHeight: item.media_details.sizes.medium.height,
                    thumbnailWidth: item.media_details.sizes.medium.width
                }
            })
            setImages(array);
        });
    }, []);
    return(
        <div>
            <Gallery images={images}
                lightboxWidth="3840"
                enableImageSelection={false}
                showLightboxThumbnails={true}
            />
        </div>
    )
}

export default GalleryPage;